<template>
  <div class="login-page">
    <div class="login-panel">
      <transition name="fade-in-slow">
        <div class="login-box" v-show="mostrarLogo">
          <div class="logo" style="text-align: center;">
            <div>
              <img src="../../assets/logo-secco.png" style="height: 50px;" />
            </div>
            <h4 style="color: #ffffff">Relevamiento</h4>
          </div>
        </div>
      </transition>
      <transition name="fade-in-slow">
        <el-form
          v-show="mostrarForm"
          :model="form"
          :rules="rules"
          ref="form"
          style="margin: auto; width: 95%;"
        >
          <el-form-item prop="username">
            <el-input
              ref="username"
              class="input-login"
              placeholder="Usuario"
              v-model="form.username"
              :autofocus="true"
              maxlength="50"
              @keyup.enter.native="login"
            ></el-input>
          </el-form-item>
          <el-form-item style="margin-bottom: 20px;" prop="password">
            <el-input
              class="input-login"
              placeholder="Contraseña"
              v-model="form.password"
              type="password"
              maxlength="20"
              @keyup.enter.native="login"
            ></el-input>
          </el-form-item>
          <el-form-item style="margin-bottom: 0px">
            <el-button
                style="width: 100%;"
                type="primary"
                @click="login"
                v-loading="cargando">Ingresar
            </el-button>
            <el-button
              style="width: 100%; color: white; margin-left: 0px; margin-top: 10px;"
              type="text"
              @click="$router.push('/olvidecontrasena/')"
              size="small"
            >Olvidé mi Contraseña</el-button>
          </el-form-item>
        </el-form>
      </transition>
    </div>
  </div>
</template>

<style>
.input-login > input {
  background-color: rgba(255, 255, 255, 0) !important;
  border-radius: 0px;
  border-bottom: 2px solid #dcdfe6 !important;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  color: #fff;
}

.login-page {
  height: 100%;
  width: 100%;
  display: flex;
}

.login-panel {
  background: #ffffff14;
  height: 450px;
  min-width: 300px;
  max-width: 300px;
  margin: auto;
  padding: 30px;
  z-index: 2;
  border-radius: 10px;
}

.login-panel .login-box .logo {
  margin-bottom: 60px;
}

.login-panel .login-box .logo > h1 {
  text-align: center;
  color: #ffffff;
}

.imagen-fondo {
  position: absolute;
  left: 30%;
  top: 30%;
  z-index: 1;
  opacity: 0.2;
}

.fade-in-slow-enter-active,
.fade-in-slow-leave-active {
  transition: opacity 2s;
}

.fade-in-slow-enter,
.fade-in-slow-leave-to {
  opacity: 0;
}
</style>

<script>
import { AUTH_REQUEST } from "../../store/actions/auth";

export default {
  name: "login",
  data() {
    return {
      form: {
        username: "",
        password: ""
      },

      mostrarLogo: false,
      mostrarForm: false,
      cargando: false
    };
  },
  computed: {
    rules() {
      let rules = {
        username: [
          {
            required: true,
            message: "Por favor introduzca un nombre de usuario.",
            trigger: "change"
          }
        ],
        password: [
          {
            required: true,
            message: "Por favor introduzca la contraseña.",
            trigger: "change"
          }
        ]
      };

      return rules;
    }
  },
  created() {
    setTimeout(() => {
      this.mostrarLogo = true;
      setTimeout(() => {
        this.mostrarForm = true;
        setTimeout(() => {
          this.$refs.username.focus()
        }, 200);
      }, 300);
    }, 50);
  },
  methods: {
    login: function() {
      this.$refs.form.validate((respuesta, data) => {
        if (respuesta) {
          let params = {
            usuario: this.form.username,
            password: this.form.password
          };

          this.cargando = true;

          this.$api
            .post(this.$api.URL + "/auth/login", params, null, 0)
            .then(respuesta => {
              this.postRealizado(respuesta);
            });
        }
      });
    },

    postRealizado(respuesta) {
      this.cargando = false;

      if (respuesta.estado != 1) {
        if (respuesta.estado == -2) {
          this.$message({
            message: "El usuario no existe.",
            type: "error"
          });
        } else if (respuesta.estado == -3) {
          this.$message({
            message: "El usuario está bloqueado.",
            type: "error"
          });
        } else if (respuesta.estado == -4) {
          this.$message({
            message:
              "El usuario está bloqueado. Cantidad de intentos excedida, intente en 5 minutos.",
            type: "error"
          });
        } else if (respuesta.estado == -5) {
          this.$message({
            message: "Sin roles autorizados.",
            type: "error"
          });
        } else {
          this.$message({
            message: "La contraseña no es correcta.",
            type: "error"
          });
        }
        return;
      }

      let user = {
        token: respuesta.datos.token,
        roles: respuesta.datos.roles,
        usuario: respuesta.datos.usuario
      };

      this.$store.dispatch(AUTH_REQUEST, user).then(() => {
        this.$router.push("/");
      });
    }
  },
  beforeCreate: function() {
    document.body.className = "body_sinlayout";
  },
  beforeDestroy: function() {
    document.body.className = "";
  }
};
</script>
